import { styled } from '@/styles/stitches';

export const InputGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '$4',
  $$btnSize: '3.375rem',

  strong: {
    color: '$errorMessage',
    fontWeight: '$medium',
    fontSize: '$xs',
    marginLeft: '$4',
  },

  button: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '$4',
    border: 0,
    background: 'transparent',
    height: '$$btnSize',
    display: 'flex',
    alignItems: 'center',

    svg: {
      color: '#B7B7B7',
    },
  },
});

export const Input = styled('input', {
  height: '$$btnSize',
  width: '100%',
  backgroundColor: '$gray-100',
  color: '#000',
  fontSize: '$md',
  borderRadius: '$lg',
  border: 'none',
  padding: '$4',
  outline: 'none',

  '&:focus-visible': {
    border: '1px solid $primary-500',
  },

  variants: {
    isInvalid: {
      true: { border: '1px solid $errorMessage' },
    },
  },
});
