import React, { useState } from 'react';
import * as S from './styles';
import NextLink from 'next/link';
import Image from 'next/image';
import VerticalLine from '@/components/global/VerticalLine';
import Button from '@/components/global/Button';

import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputWithValidation from '@/components/global/InputWithValidation';
import { useRouter } from 'next/router';
import axios from 'axios';
import { toast } from 'react-toastify';

type LoginFormData = {
  email: string;
  password: string;
};

const LoginFormValidation = yup.object().shape({
  password: yup.string().required('Digite sua senha!'),
  email: yup.string().email().required('Informe seu e-mail'),
});

export default function SignInForm() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function submit(data: LoginFormData): Promise<void> {
    setIsLoading(true);
    try {
      await axios.post('/api/login', {
        email: data.email,
        password: data.password,
      });

      router.push('/home');
    } catch (error: any) {
      if (error.response.status === 401) {
        toast(`Erro ao fazer login. Email ou senha incorreto.`, {
          type: 'error',
        });
      } else {
        toast(`Houve um erro ao fazer login. Tente novamente mais tarde.`, {
          type: 'error',
        });
      }
      setIsLoading(false);
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormValidation),
  });

  return (
    <S.Container>
      <S.LogoArea>
        <Image src="/images/nextOED.svg" width={151} height={52} />
        <VerticalLine margin="0 20px" height="80%" />
        <Image src="/images/dorina.svg" width={105} height={53} />
      </S.LogoArea>

      <S.FormArea onSubmit={handleSubmit(submit)}>
        <p>Login</p>
        <InputWithValidation
          {...register('email')}
          title="Email"
          type="email"
          placeholder="E-mail"
          errorMessage={errors.email?.message}
        />
        <div>
          <InputWithValidation
            {...register('password')}
            title="Senha"
            type="password"
            placeholder="Senha"
            errorMessage={errors.password?.message}
          />
        </div>
        <NextLink prefetch={false} passHref href="/esqueci-minha-senha">
          <S.Link>Esqueceu sua senha?</S.Link>
        </NextLink>
        <div style={{ marginTop: '3rem' }}>
          <Button
            isSubmiting={isLoading}
            type="submit"
            label={isLoading ? 'carregando...' : 'Login'}
          />
        </div>
      </S.FormArea>
    </S.Container>
  );
}
