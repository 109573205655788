import React, { ButtonHTMLAttributes } from 'react';
import { ButtonBase } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  isSubmiting?: boolean;
};

export default function Button({ label, isSubmiting, ...rest }: ButtonProps) {
  return (
    <ButtonBase {...rest} disabled={isSubmiting} isSubmiting={isSubmiting}>
      {isSubmiting ? 'Carregando...' : label}
    </ButtonBase>
  );
}
