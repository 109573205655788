import React, {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
  forwardRef,
} from 'react';

import { BsEye, BsEyeSlash } from 'react-icons/bs';
import * as S from './styles';

type InputUnAuthProps = InputHTMLAttributes<HTMLInputElement> & {
  errorMessage?: string;
  isPassword?: boolean;
};

const InputWithValidationBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputUnAuthProps
> = ({ errorMessage, ...props }, ref) => {
  const isPassword = props.type === 'password';
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(isPassword);

  return (
    <S.InputGroup>
      <S.Input
        ref={ref}
        {...props}
        type={hiddenPassword ? 'password' : 'text'}
        isInvalid={!!errorMessage}
      />
      {!!isPassword && (
        <button
          type="button"
          onClick={() => setHiddenPassword(!hiddenPassword)}
        >
          {hiddenPassword ? <BsEye size="24" /> : <BsEyeSlash size="24" />}
        </button>
      )}

      {!!errorMessage && <strong>{errorMessage}</strong>}
    </S.InputGroup>
  );
};

export default forwardRef(InputWithValidationBase);
