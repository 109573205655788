import { styled } from '@/styles/stitches';

export const ButtonBase = styled('button', {
  width: '100%',
  height: '40px',
  color: '$white-100',
  backgroundColor: '$secondary-500',
  borderRadius: '$lg',
  border: 'none',

  '&:hover': {
    backgroundColor: '$secondary-400',
  },
  '&[disabled]': {
    // opacity: 0.5,
    cursor: 'not-allowed',
  },
  variants: {
    // disabled: {
    //   true: {
    //     backgroundColor: '$primary100',
    //   },
    // },
    isSubmiting: {
      true: {
        opacity: 0.8,
        cursor: 'not-allowed',
        '&:hover': {
          backgroundColor: '$secondary-500',
        },
      },
    },
  },
});
