import React from 'react';

type VerticalLineProps = React.CSSProperties & {
  color?: string;
  height?: string;
};

export default function VerticalLine({
  color = '#979797',
  height = '100%',
  ...props
}: VerticalLineProps) {
  return (
    <div
      style={{
        ...props,
        height: height,
        backgroundColor: color,
        width: '2px',
      }}
    ></div>
  );
}
