import { styled } from '@/styles/stitches';

export const Box = styled('div', {
  background: '#FFF',
  borderRadius: '$lg',
  padding: '$7',
  fontSize: '6xl',
  fontWeight: '$bold',
  width: '200px',
  height: 'fit-content',
});
