import { styled } from '@/styles/stitches';

export const Container = styled('div', {
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  position: 'relative',
  background: '$white-100',
});

export const SubContainer = styled('div', {
  margin: '0 auto',
  padding: '$5',
  width: '100%',
  minHeight: '100vh',
  maxWidth: '90rem',

  '@md': {
    padding: '0',
  },
});

export const Wrapper = styled('div', {
  width: '100%',
  display: 'flex',
  minHeight: '100%',

  padding: '1.875rem',
  borderRadius: '$md',

  backgroundImage: 'url(/images/login_background.svg)',
  backgroundSize: 'cover',

  '@md': {
    borderRadius: '0',
    padding: '$4',
    justifyContent: 'center',
  },
});

export const PinsContainer = styled('div', {
  display: 'flex',
  paddingLeft: '$6',
  flex: '1',
  '@md': {
    display: 'none',
  },
});

export const PinsSubcontainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '80%',
  height: '100%',

  '@lg': {
    width: '100%',
  },
});
