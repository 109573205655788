import FloatBox from '@/components/pages/SignIn/FloatBox';
import SignInForm from '@/components/pages/SignIn/Form';
import * as S from '@/styles/pages/signIn';
import { getSession } from '@/utils/getSession';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import React, { useEffect } from 'react';

export default function Home() {
  return (
    <S.Container>
      <Head>
        <title>NextOED - Login</title>
      </Head>
      <S.SubContainer>
        <S.Wrapper>
          <SignInForm />
          <S.PinsContainer>
            <S.PinsSubcontainer>
              <FloatBox />
            </S.PinsSubcontainer>
          </S.PinsContainer>
        </S.Wrapper>
      </S.SubContainer>
    </S.Container>
  );
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  try {
    const response = await getSession(req);
    return {
      props: {
        userName: response.name,
      },
      redirect: {
        destination: '/home',
        permanent: false,
      },
    };
  } catch (err) {
    return {
      props: {},
    };
  }
};
