import { styled } from '@/styles/stitches';

export const Container = styled('div', {
  backgroundColor: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  width: '510px',
  borderRadius: '$lg',
  padding: '$15 1.875rem',
});

export const FormArea = styled('form', {
  padding: '0 $14',
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  div: {
    width: '100%',
    display: 'flex',
    position: 'relative',
  },

  p: {
    fontSize: '3.125rem',
    color: '$black-100',
    fontWeight: '$semiBold',
    marginBottom: '$8',
  },

  '@md': {
    padding: '0 $4',
  },
});

export const Link = styled('a', {
  textDecoration: 'none !important',
  cursor: 'pointer',
  color: '$primary-500',
  width: 'fit-content',
});

export const LogoArea = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});
